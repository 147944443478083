<template>
    <div class="clients-container">

      <admin-header-vue
        v-if="showCategory"
        :alias="headerAlias"
        :canCreate="false"
        @onCreate="onCreate"
        @openUploadModal="handleModal(true)"
        pageName="" />
      <div class="user-table">
        <table-vue
          :labels="labels"
          :dateFormat="dateFormat"
          :data="getNewsRssData()"
          :sortObj="sort"
          :canDelete="false"
          :totalCount="totalCount"
          :currentPage="currentPage"
          :limit="limit"
          :sortValidation="sortValidation"
          :categoryFilterNeeded="false"
          :titleData="titleData"
          :showViewAction="true"
          :showEditAction="true"
          :showDeleteAction="true"
          :showAction="false"
          @searchText="searchText"
          @onChangeSort="onChangeSort"
          @updatePage="updatePage"
          @updateLimit="updateLimit">
        </table-vue>
      </div>
      <modal-vue
        :modal="modal"
        @onChange="handleModal">
        <div class="modal-display-container rss-links-modal">
          <p class="modal-header">Upload RSS Links</p>
          <div class="modal-data">
            <div class="local-loader-container" v-if="loader">
              <div class="inava-loader"></div>
            </div>
            <div class="upload-container">
              <div class="file-tag">
                File: <span class="filename" v-if="file">{{file.name}}</span>
                <span v-if="file" class="clear" @click="clearFile">Clear</span>
              </div>
              <b-field>
                <b-upload v-model="file" drag-drop ref="uploadComponent">
                  <section>
                    <div class="content has-text-centered">
                      <p>
                        <b-icon
                            icon="upload"
                            size="is-large">
                        </b-icon>
                      </p>
                      <p>Drop your file here or click to upload</p>
                    </div>
                  </section>
                </b-upload>
              </b-field>
            <div class="error" v-for="err, index in validationErrorList" :key="index" v-show="validationErrorList.length">Error: {{err}}</div>
            </div>
            <div class="actions">
              <b-button @click="handleModal(false)">Cancel</b-button>
              <b-button :disabled="(!isValid || file === null)" @click="uploadLinks">Upload</b-button>
            </div>
          </div>
        </div>
      </modal-vue>
    </div>
  </template>

<script>
import { mapActions, mapState } from 'vuex'
import tableVue from '@/views/Admin/components/table.vue'
import AdminHeaderVue from '@/views/Admin/components/AdminHeader.vue'
import { metaTitle } from '@/constant/data'
import ModalVue from '@/views/Admin/components/Modal.vue'
import Snackbar from '@/components/Snackbar'
import { downloadCSV } from '@/util/util'

export default {
  name: 'newsRssFeed',
  metaInfo () {
    return {
      title: metaTitle.newsRssFeed
    }
  },
  components: {
    tableVue,
    AdminHeaderVue,
    ModalVue

  },
  data () {
    return {
      headerAlias: 'news feed',
      titleData: 'newsRssFeed',
      dateFormat: ['createdAt', 'updatedAt', 'lastVisited'],
      labels: [
        { name: 'RSS ID', alias: 'id' },
        { name: 'Feed URL', alias: 'url' },
        { name: 'Publisher', alias: 'publication' },
        { name: 'Type', alias: 'type' },
        { name: 'status', alias: 'status' },
        { name: 'Last visited', alias: 'lastVisited' },
        { name: 'Updated At', alias: 'updatedAt' }
      ],
      sortValidation: ['id', 'type', 'status', 'lastVisited', 'updatedAt'],
      search: null,
      sort: { by: 'asc', field: 'id' },
      categoryFilterNeeded: true,
      modal: false,
      file: null,
      isValid: true,
      validationErrorList: []
    }
  },
  computed: {
    ...mapState('newsRssFeed', ['newsRssFeed', 'totalCount']),
    ...mapState('adminCommon', ['tableSelectionState', 'loader']),
    ...mapState('user', ['userDetails']),
    showCategory () {
      if ([...this.userDetails.permissions.filter((obj) => this.$router.history.current.path.includes(obj.path) && obj.write)].length) {
        return true
      }
      return false
    },
    showEdit () {
      if ([...this.userDetails.permissions.filter((obj) => this.$router.history.current.path.includes(obj.path) && obj.update)].length) {
        return true
      }
      return false
    },
    showDelete () {
      if ([...this.userDetails.permissions.filter((obj) => this.$router.history.current.path.includes(obj.path) && obj.delete)].length) {
        return true
      }
      return false
    },
    limit: {
      get () {
        return this.tableSelectionState ? this.tableSelectionState.category.limit : 10
      },
      set (newLimit) {
        this.tableSelectionState.category.limit = newLimit
      }
    },
    currentPage: {
      get () {
        return this.tableSelectionState ? this.tableSelectionState.category.currentPage : 1
      },
      set (newPage) {
        this.tableSelectionState.category.currentPage = newPage
      }
    }
  },
  watch: {
    file () {
      if (this.file) {
        this.validationErrorList = []
        this.isValid = this.validateFile()
      }
    },
    modal () {
      if (!this.modal) {
        this.$nextTick(() => {
          this.file = null
          this.validationErrorList = []
        })
      }
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    ...mapActions('newsRssFeed', ['getNewsRssFeed', 'addRssFeedsFromFile']),
    ...mapActions('adminCommon', ['toggleLoaderState']),
    clearFile () {
      this.file = null
      this.validationErrorList = []
    },
    validateFile () {
      if (this.file.name === '' || this.file.name === null || this.file.name === undefined) {
        this.validationErrorList.push('File is invalid')
      }
      const FILE_SIZE_MAX = 5 * 1024 * 1024
      if (this.file.size > FILE_SIZE_MAX) {
        this.validationErrorList.push('File cannot be greater than 5MB')
      }
      if (this.file.name && this.file.name.slice(-4) !== '.csv') {
        this.validationErrorList.push('Please upload a .csv file only')
      }
      if (this.validationErrorList.length > 0) {
        return false
      }
      return true
    },
    getNewsRssData () {
      return this.newsRssFeed.map((ele) => {
        if (ele.publication !== null) {
          return { ...ele, publication: ele.publication.name }
        } else {
          return ele
        }
      })
    },
    async uploadLinks () {
      if (this.isValid) {
        this.validationErrorList = []
        const formData = new FormData()
        formData.append('file', this.file)
        const result = await this.addRssFeedsFromFile(formData)
        if (result.status === 201) {
          if (result.successfulUploads) {
            this.modal = false
            Snackbar({ message: result.info, type: 'is-success' })
          } else {
            Snackbar({ message: result.info, type: result.info === 'Upload Failed' ? 'is-danger' : 'is-success' })
            Snackbar({ message: ' Downlading failed links.', type: 'is-white' })
            downloadCSV(result.data, `Failed Links - ${this.file.name.slice(0, -4)}.csv`)
          }
        } else {
          if (result.info !== 'Upload Failed') {
            this.validationErrorList.push(result.info)
          }
          Snackbar({ message: 'RSS Link upload failed', type: 'is-danger' })
        }
      }
    },
    handleModal (value) {
      this.modal = value
    },

    /** This method redirects the user to the *add news rss* page
       * @public
       */
    onCreate () {
      // this.$router.push('category/add')
    },

    /** This method updates the sorting criteria of the table
       * @public
       */
    onChangeSort (type) {
      this.sort = type
      this.getData()
    },
    /** This method updates the table page that is being viewed
       * @public
       */
    updatePage (data) {
      this.currentPage = data
      this.getData()
    },
    /** This method updates the number of rows that are being shown by the table
       * @public
       */
    updateLimit (data) {
      this.limit = data
      this.getData()
    },
    /** This method updates the search keyword and triggers the *getData()* method
       * @param text {String} - The new search keyword
       */
    searchText (text) {
      this.search = text
      if (this.search !== null) {
        this.currentPage = 1
        this.getData()
      }
    },
    /** This method fetches the news rss feed related data from the database, using the limit/search/sort parameters specified */
    getData () {
      if (this.search === '') {
        this.search = null
      }
      this.getNewsRssFeed({
        status: 'all',
        limit: this.limit,
        offset: (this.currentPage - 1) * this.limit,
        order: this.sort.by,
        orderBy: this.sort.field,
        searchContent: this.search
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .clients-container {
    margin: 20px 0px;
    border-radius: 4px;
    background: var(--primary);
    .relative {
      position: relative;
    }
  }

  ::v-deep .modal .modal-content {
    width: fit-content;
  }

  .modal-display-container {
    width: 500px;
  }
  ::v-deep .upload.control {
    width: 100%;
  }
  ::v-deep .upload-draggable {
    padding: 25px;
    margin: 10px auto 0px;
    position: relative;
    width: 75%;
    cursor: pointer;
    &:hover {
      border-color: #b5b5b5 !important;
    }
  }
  .error {
    font-size: 14px;
    color: var(--inava-pink);
    margin: 0 auto;
    border-radius: 2px;
    width: 75%;
  }
  .file-tag {
    font-size: 13px;
    display: flex;
    align-items: center;
    width: 75%;
    margin: 0 auto;
    cursor: unset !important;
    .clear {
      font-size: 12px;
      margin-left: 5px;
      font-weight: 500;
      color: var(--rss-modal-clear);
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    .filename {
      margin-left: 5px;
      display: inline-block;
      background: var(--rss-modal-filename-background);
      color: var(--rss-modal-filename-color);
      padding: 2px 10px;
      border-radius: 3px;
      max-width: 70%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      position: relative;
      svg {
        position: absolute;
        top: 3px;
        right: 3px;
        width: 5px;
        fill: var(--inava-dark-gray);
      }
    }
  }
</style>
